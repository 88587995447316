<template>
	<div>
		<slack-settings />
	</div>
</template>
<script>
import SlackSettings from "@/components/integrations/slack/SlackSettings.vue";
export default {
	name: 'Slack',
	meta: {
		title: 'Alert Cascade - Slack'
	},
	components: { SlackSettings },
}

</script>
